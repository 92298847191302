<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      {
        expanded:
          !isVerticalMenuCollapsed ||
          (isVerticalMenuCollapsed && isMouseHovered),
      },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link class="navbar-brand" to="/student_list">
              <h2
                v-if="
                  !isVerticalMenuCollapsed ||
                  (isVerticalMenuCollapsed && isMouseHovered)
                "
                class="brand-text"
              >
                <img class="fp_logo" :src="logo" alt="" />
              </h2>
              <span v-else class="brand-logo">
                <b-img :src="logo" alt="logo" />
              </span>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="
        (evt) => {
          shallShadowBottom = evt.srcElement.scrollTop > 0;
        }
      "
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->

    <!-- <div class="navigation-menu__services-list px-2">
      <div class="px-1">
        <h5 class="text-muted">Current Plan</h5>
      </div>
      <div class="services-list__card px-1 py-1 mt-1">
        <div>
          <label class="text-primary">Service Name</label>
          <h5 class="text-success">DESIRED</h5>
        </div>
        <div>
          <label class="text-primary">Service Name</label>
          <h5 class="text-success">FULL COURSE</h5>
        </div>
        <div>
          <label class="text-primary">Edgeout</label>
          <h5 class="text-success">DESIRED</h5>
        </div>
        <div class="mt-2" role="button">
          <p class="text-secondary mb-0">View All Plans</p>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import AwayLogo from "@/assets/images/logo/away.png";
import navMenuItemsList from "@/navigation/vertical";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BLink, BImg } from "bootstrap-vue";
import { provide, computed, ref } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";
import VerticalNavMenuItems from "./components/vertical-nav-menu-items/VerticalNavMenuItems.vue";
import useVerticalNavMenu from "./useVerticalNavMenu";
import { FILESURL } from "@/config";

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  data() {
    return {};
  },
  computed: {
    logo() {
      const user_details = this.$store.getters["user/getUserDetails"];

      if (user_details.brand_logo) {
        return `${FILESURL}${user_details.brand_logo}`;
      }
      return AwayLogo;
    },
    navMenuItems() {
      const user_details = this.$store.getters["user/getUserDetails"];

      let user_type = user_details.user_type;

      if (user_type) {
        const arr = [];
        navMenuItemsList.forEach((item) => {
          if (!item.children || !item.children.length) {
            if (item.user_types.includes(user_type)) {
              arr.push(item);
            }
            return;
          }
          const children = [];
          item.children.forEach((child) => {
            if (child.user_types && child.user_types.includes(user_type)) {
              children.push(child);
              return;
            }

            // Dynamic Resource Allocation
            if (
              child.resource &&
              user_details.allocated_resources &&
              user_details.allocated_resources.length &&
              user_details.allocated_resources.includes(child.resource)
            ) {
              children.push(child);
              return;
            }
          });
          if (children.length) {
            item.children = children;
            arr.push(item);
          }
        });
        return arr;
      }
      return [];
    },
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props);

    const { skin } = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide("isMouseHovered", isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === "unpinned" ? "CircleIcon" : "DiscIcon"
    );

    // App Name
    const { appName, appLogoImage } = $themeConfig.app;

    return {
      // navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    };
  },
};
</script>

<style lang="scss">
.fp_logo {
  height: 60px;
  width: 200px;
  // margin-bottom: 35px;
  position: absolute;
  top: 15px;
  left: 15px;

  // bottom: 10px;
}

@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
