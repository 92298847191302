export default [
  {
    title: "Home",
    route: "home",
    icon: "HomeIcon",
    user_types: ["student"],
  },
  {
    title: "Home",
    route: "AgentHome",
    icon: "HomeIcon",
    user_types: ["agent", "franchise"],
  },
  {
    title: "Home",
    route: "StaffHome",
    icon: "HomeIcon",
    user_types: ["operations", "counselor", "visa"],
  },
  // {
  //   title: "Home",
  //   route: "CounselorHome",
  //   icon: "HomeIcon",
  //   user_types: ["counselor"],
  // },
  {
    title: "Home",
    route: "SchoolCounselorHome",
    icon: "HomeIcon",
    user_types: ["school_counselor"],
  },
  {
    title: "Profile Form",
    route: "profile_form",
    icon: "UserIcon",
    user_types: ["student"],
  },
  {
    title: "University Search",
    route: "University Search",
    icon: "SearchIcon",
    user_types: [
      "student",
      "counselor",
      "agent",
      "franchise",
      "operations",
      "visa",
      "school_counselor",
    ],
  },
  {
    title: "Application",
    route: "application",
    icon: "PackageIcon",
    user_types: ["student"],
  },
  {
    title: "Students List",
    route: "Student List",
    icon: "ListIcon",
    user_types: [
      "operations",
      "visa",
      "counselor",
      "agent",
      "franchise",
      "school_counselor",
    ],
  },
  {
    title: "Applications",
    route: "Student Applications",
    icon: "GridIcon",
    user_types: [
      "operations",
      "visa",
      "counselor",
      "agent",
      "franchise",
      "school_counselor",
    ],
  },
  // {
  //   title: 'Explore',
  //   route: 'Explore',
  //   icon: 'SearchIcon',
  //   user_types: ['counselor', "visa", 'student_counselor', 'agent', 'franchise, 'operations']
  // },
  {
    title: "Documents",
    route: "documents",
    icon: "FileTextIcon",
    user_types: ["student"],
  },
  // {
  //   title: "Services",
  //   route: "services",
  //   icon: "LayersIcon",
  //   user_types: ["student", "agent", "franchise", "school_counselor"],
  // },
  // {
  //   title: "Services",
  //   icon: "LayersIcon",
  //   route: "services",
  //   user_types: ["student", "counselor", "agent", "franchise"],
  // },
  // {
  //   title: "Tasks",
  //   icon: "LayersIcon",
  //   route: "apps-todo",
  //   user_types: [
  //     // "student",
  //     "counselor",
  //     // "agent",
  //     // "franchise",
  //     "operations",
  //     "visa",
  //     // "school_counselor"
  //   ],
  // },
  // {
  //   title: "Announcements",
  //   route: "Announcements",
  //   icon: "ClockIcon",
  //   user_types: ["student", "counselor", "operations", "visa"],
  // },
  // {
  //   title: "Leads",
  //   route: "Leads",
  //   icon: "LayersIcon",
  //   user_types: ["counselor"],
  // },
  // {
  //   title: "Chat",
  //   route: "chat",
  //   icon: "MessageSquareIcon",
  //   user_types: [
  //     "student",
  //     "counselor",
  //     "agent",
  //     "franchise",
  //     "operations",
  //     "visa",
  //     "school_counselor",
  //   ],
  // },
  {
    title: "Notifications",
    route: "Notifications",
    icon: "BellIcon",
    user_types: [
      // "student",
      "counselor",
      "agent",
      "franchise",
      "operations",
      "visa",
      "school_counselor",
    ],
  },
  // {
  //   title: "My Agents",
  //   route: "Assigned Agents",
  //   icon: "GridIcon",
  //   user_types: ["counselor"],
  // },
  {
    title: "Resources",
    route: "resources",
    icon: "PackageIcon",
    children: [
      {
        title: "Training Video",
        route: "TrainingHub",
        resource: "training_hub",
        // user_types: ["agent", "franchise"],
      },
      {
        title: "Market Insights",
        route: "MarketingInsights",
        resource: "marketing_insight",
        // user_types: ["agent", "franchise"],
      },
      {
        title: "Community Help",
        route: "CommunityHelp",
        resource: "community_help",
        // user_types: ["agent", "franchise"],
      },
      {
        title: "University USP",
        route: "University USP",
        resource: "university_usp",
        // user_types: ["agent", "franchise"],
      },
    ],
  },
  {
    title: "Sample Document",
    route: "SampleDocument",
    icon: "FileTextIcon",
    resource: "sample_document",
    user_types: [
      // "student",
      "counselor",
      "agent",
      "franchise",
      "operations",
      "visa",
      "school_counselor",
    ],
  },
];
